import React, { useRef } from "react";
import { Link } from "react-router-dom";
// import logo from "../media/images/ft-red-logo.png";
// import logo from "../media/images/ft-red-logo2.png";
import logo from "../media/images/ft-logo-1.png";
// import logo from "../media/images/logo.png";

const Navbar = () => {
  
  const navRef = useRef(); 

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  return (
    <div>

      <div className="navbar">
        <div className="navbar-top">
          <div>
            <div className="navbar-top-list">
              <div className="navbar-top-list-icon"><i className="fa-solid fa-envelope"></i></div>
              <div className="navbar-top-list-text">
                <Link title=" Mobile Dog Grooming Near Me" target="blank" to="mailto:info@fluffytouch.in">info@fluffytouch.in</Link>
                <div>Email address</div>
              </div>
            </div>

            <div className="navbar-top-list">
              <div className="navbar-top-list-icon"><i className="fa-solid fa-phone"></i></div>
              <div className="navbar-top-list-text">
                <Link title="Dog Grooming" target="blank" to="tel:+91 9658-189-189">+91 9658-189-189</Link>
                <div>Phone line</div>
              </div>
            </div>
            <div className="navbar-top-list">
              <div className="navbar-top-list-icon"><i className="fa-brands fa-whatsapp"></i></div>
              <div className="navbar-top-list-text">
                <Link title="Cat Grooming" target="_blank" to="https://wa.me/+919658189189?text=Hello ! I'm Interested in Doorstep Pet Grooming Service. Please contact me.">+91 9658-189-189</Link>
                <div>Whatsapp</div>
              </div>
            </div>
          </div>
        </div>

        <div className="navbar-bottom">
          <div>
            <Link title="Mobile Pet Grooming" to="/" className="navbar-bottom-logo">
                <img title="Cat Grooming Near Me" src={logo} alt="fluffy-touch logo" />
            </Link>
            <div className="navbar-bottom-menu">
            <Link title="Best Pet Grooming Near Me" to="/">Home</Link>
            
            <div className="navbar-drop">
                <span>Price <i className="fa-solid fa-caret-down"></i></span>
                <div className="navbar-drop-option">
                  <Link title="pet grooming" to="/pet-grooming">Pet Grooming</Link>
                  <Link title="pet boarding" to="/pet-boarding">Pet Boarding</Link>
                  <Link title="pet hair cutting" to="/pet-hair-cutting">Pet Designer Haircut</Link>
                </div>
              </div>

              <div className="navbar-drop">
                <span>Grooming <i className="fa-solid fa-caret-down"></i></span>
                <div className="navbar-drop-option">
                  <Link title="dog grooming page" to="/dog-grooming">Dog Grooming</Link>
                  <Link title="cat grooming page" to="/cat-grooming">Cat Grooming</Link>
                </div>
              </div>

              <div className="navbar-drop">
                <span>Boarding <i className="fa-solid fa-caret-down"></i></span>
                <div className="navbar-drop-option">
                  <Link title="dog boarding page" to="/dog-boarding">Dog Boarding</Link>
                  <Link title="cat boarding page" to="/cat-boarding">Cat Boarding</Link>
                </div>
              </div>
              
              
              {/* <Link title="Pet Grooming Near Me" to="/price">Prices</Link> */}
              {/* <Link title="Small Animal Grooming Near Me" to="/pet-grooming">Grooming</Link> */}
              {/* <Link title="Pet Grooming" to="/pet-boarding">Boarding</Link> */}
              <div className="navbar-drop">
                <span>Company <i className="fa-solid fa-caret-down"></i></span>
                <div className="navbar-drop-option">
              <Link title="Home Pet Grooming Near Me" to="/about">About us</Link>
              <Link title="Groomers Near Me" to="/gallery">Gallery</Link>
                </div>
              </div>
              
              <Link title=" Mobile Dog Grooming Near Me" to="/blog">Blog</Link>
              <Link title="Dog Grooming Near Me" to="/contact">Contact us</Link>
              <Link className="navbar-bottom-menu-button" title="Dog Grooming Near Me" to="/book-an-appointment">Book Now</Link>
            </div>
          </div>
        </div>
      </div>


      {/*  */}
      <div className="navbar-small">

      {/* <div className="nav-head-top">
        <div className="nav-head-top-heading-1">
          Get Upto 40% Off On All Services
        </div>
        <div className="nav-head-top-heading-2">*Limited Period Offer</div>
      </div> */}

      <div className="nav-header"></div>
      <div className="nav-small">
      <Link to="/" className="nav-small-logo">
        <img title="Cat Grooming Near Me" src={logo} alt="ft-logo" />
      </Link>

      <nav ref={navRef}>



              <div className="navbar-menu">
            <Link title="Best Pet Grooming Near Me" to="/">Home</Link>
            
            <div className="navbar-drop">
                <span>Price <i className="fa-solid fa-caret-down"></i></span>
                <div className="navbar-drop-option">
                  <Link title="pet grooming" to="/pet-grooming">Pet Grooming</Link>
                  <Link title="pet boarding" to="/pet-boarding">Pet Boarding</Link>
                  <Link title="pet hair cutting" to="/pet-hair-cutting">Pet Designer Haircut</Link>
                </div>
              </div>

              <div className="navbar-drop">
                <span>Grooming <i className="fa-solid fa-caret-down"></i></span>
                <div className="navbar-drop-option">
                  <Link title="dog grooming page" to="/dog-grooming">Dog Grooming</Link>
                  <Link title="cat grooming page" to="/cat-grooming">Cat Grooming</Link>
                </div>
              </div>

              <div className="navbar-drop">
                <span>Boarding <i className="fa-solid fa-caret-down"></i></span>
                <div className="navbar-drop-option">
                  <Link title="dog boarding page" to="/dog-boarding">Dog Boarding</Link>
                  <Link title="cat boarding page" to="/cat-boarding">Cat Boarding</Link>
                </div>
              </div>
              
              
              {/* <Link title="Pet Grooming Near Me" to="/price">Prices</Link> */}
              {/* <Link title="Small Animal Grooming Near Me" to="/pet-grooming">Grooming</Link> */}
              {/* <Link title="Pet Grooming" to="/pet-boarding">Boarding</Link> */}
              <div className="navbar-drop">
                <span>Company <i className="fa-solid fa-caret-down"></i></span>
                <div className="navbar-drop-option">
              <Link title="Home Pet Grooming Near Me" to="/about">About us</Link>
              <Link title="Groomers Near Me" to="/gallery">Gallery</Link>
                </div>
              </div>
              
              <Link title=" Mobile Dog Grooming Near Me" to="/blog">Blog</Link>
              <Link title="Dog Grooming Near Me" to="/contact">Contact us</Link>
            </div>
              
            <button className="nav-btn nav-close-btn" onClick={showNavbar}><i className="fa-solid fa-circle-xmark"></i></button>
          </nav>
      
      <div className="nav-small-icon"  onClick={showNavbar}>
      <i className="fa-solid fa-bars"></i>
      </div>
        
      </div>
      </div>
    </div>
  );
};

export default Navbar;
