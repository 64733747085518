import React from 'react'
import BoardingPriceComponent from './BoardingPriceComponent'

const BoardingPagePrice = () => {
  return (
      <div className="boarding-page-price">
            <div>
            <div className="boarding-page-price-heading">Our <span>Pet Boarding</span> Services</div>
            
<BoardingPriceComponent/>
        </div>
        
    </div>
  )
}

export default BoardingPagePrice