import React from 'react'
import Navbar from '../components/Navbar'
import BreadCrumb from '../components/BreadCrumb'
import Footer from '../components/Footer'
import PetBoardingFeatures from '../components/PetBoardingFeatures'
import PetBoardingFacility from '../components/PetBoardingFacility'

import { Helmet } from "react-helmet";
import DogBoardingPagePrice from '../components/DogBoardingPagePrice'

const CatBoardingPage = () => {
  return (
    <div>

<Helmet>
        <title>Book Best Cat and Dog Boarding Service by Fluffy Touch</title>
        <meta
          name="description"
          content="
          Cat Boarding Service : Book best cat boarding service in Delhi NCR, Noida at fluffytouch.in. Get pick up and drop for your pet @ Rs20/km.
          "
          
        />
      </Helmet>

      
        <Navbar/>
        <BreadCrumb name="Cat Boarding" title="Best Cat Boarding Centre in Noida, NCR"/>

        
        
        
        <PetBoardingFeatures/>
        <DogBoardingPagePrice pet="Cat"/>
        <PetBoardingFacility/>
        <Footer/>
        
    </div>
  )
}

export default CatBoardingPage