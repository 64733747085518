import React from 'react'
import CountUp from "react-countup";

const NumberIncrement = () => {
  return (
        <div className="header-numbers">
    <div>

        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={10} duration="5" enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">
            Years of experience
          </div>
        </div>

        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={4} duration="4" enableScrollSpy />
            <span>.<CountUp end={9} duration="5" enableScrollSpy /> </span>/5
          </div>
          <div className="header-numbers-col-txt">
            {" "}
            Rating on Google
          </div>
        </div>


        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={10} duration="5" enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">Available Cities</div>
        </div>
        
        <div className="header-numbers-col">
          <div className="header-numbers-col-inc">
            <CountUp end={25000} enableScrollSpy />+
          </div>
          <div className="header-numbers-col-txt">Happy Customers</div>
        </div>
      </div>
    </div>
  )
}

export default NumberIncrement