import React from 'react'
import Navbar from '../components/Navbar'
import BreadCrumb from '../components/BreadCrumb'
import Footer from '../components/Footer'
import PetBoardingComponent from '../components/PetBoardingComponent'
import PetBoardingFeatures from '../components/PetBoardingFeatures'
import PetBoardingFacility from '../components/PetBoardingFacility'
import BoardingPagePrice from '../components/BoardingPagePrice'

import { Helmet } from "react-helmet";

const PetBoardingPage = () => {
  return (
    <div>

<Helmet>
        <title>Book Best Cat and Dog Boarding Service by Fluffy Touch</title>
        <meta
          name="description"
          content="
          Dog Boarding Service : Book best dog boarding service in Delhi NCR, Noida at fluffytouch.in. Get pick up and drop for your pet @ Rs20/km."
        />
      </Helmet>

      
        <Navbar/>
        <BreadCrumb name="Pet Boarding" title="Best Pet Boarding Centre in Noida, NCR"/>
        <PetBoardingComponent/>
        <PetBoardingFeatures/>
        <BoardingPagePrice/>
        <PetBoardingFacility/>
        <Footer/>
        
    </div>
  )
}

export default PetBoardingPage