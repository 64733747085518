import React, { useState } from 'react'
import BoardingFormComponent from './form/BoardingFormComponent'


const BoardingPriceComponent = () => {
  const [boardingForm, setBoardingForm] = useState(false)
  const [service, setService] = useState("")
  return (
    <div>
        {
            boardingForm?
            <BoardingFormComponent setBoardingForm={setBoardingForm} service={service}/>
            :""
        }
        <div className="package-content-list">
                        <div className="package-content-list-heading"><span>Pet Boarding</span> Price</div>
                        {/* <div className="package-content-list-heading-1">Get Free Pet Full Body Grooming (worth Rs. 2,199/-) And Free Obeys Training, Upto 7-Days Boarding Service</div> */}

                           
                        <div className="package-content-list-columns">

                            <div className="package-content-list-col">
                                <div>   
                                    <div className="package-content-list-col-heading-1">Day Boarding</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 799</s> Rs. 499/-</div>
                                    <div className="package-content-list-col-point">8 Am to 6 Pm, 1 meal included</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Day Boarding   -   Rs. 499/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Per Day Boarding</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 1,199</s> Rs. 699/-</div>
                                    <div className="package-content-list-col-point">Any day of your choice</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Per Day Boarding  -   Rs. 699/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            {/* <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Silver Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 6,500</s> Rs. 3,999/-</div>
                                    <div className="package-content-list-col-point">Any 7 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Silver Plan   -   Rs. 3,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Gold Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 12,000</s> Rs. 6,999/-</div>
                                    <div className="package-content-list-col-point">Any 14 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Gold Plan   -   Rs. 6,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Diamond Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 22,000</s> Rs. 11,999/-</div>
                                    <div className="package-content-list-col-point">Any 30 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Diamond Plan   -   Rs. 11,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Platinum </div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 60,000</s> Rs. 29,999/-</div>
                                    <div className="package-content-list-col-point">Any 90 days in a year</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Platinum   -   Rs. 29,999/-")}} >Book Appointment</div>
                                </div>
                            </div>

                            <div className="package-content-list-col">
                                <div>
                                    <div className="package-content-list-col-heading-1">Black Plan</div>
                                    <div className="package-content-list-col-heading-4"><s>Rs. 2,00,000</s> Rs. 99,999/-</div>
                                    <div className="package-content-list-col-point">Long term 1 year plan (365 days)</div>
                                    <div className="package-content-list-col-button"  onClick={e=>{setBoardingForm(true); setService("Black Plan   -   Rs. 99,999/-")}} >Book Appointment</div>
                                </div>
                            </div> */}

                        </div>

      


                    </div>
    </div>
  )
}

export default BoardingPriceComponent